export interface MatrixSize {
    size: string;
    class: string;
}

export interface ConsequenceArea {
    id: number;
    name: string;
}

export interface MatrixSetupItem {
    Type: string;
    FValue: number;
    CValue: number;
    Color: string;
    Frequency: string;
    TextColor: string;
    CalculatedCriticality: number;
    FrequencyPercentRange: string;
}

export interface ConsequenceValue {
    Value: number;
    Consequence: string;
}

export interface RiskID {
    ID: number;
}

export interface RiskItem {
    Risk_ID: number;
    Title: string;
    Criticality: number;
    PreviousCriticality: number;
    ConsequenceArea_ID: number;
    IsCurrentAssessmentBeforeMeasures: boolean;
    IsCurrentAssessmentAfterMeasures: boolean;
}

export interface EmittedValues {
    Clicked : boolean,
    MatrixCValue: number
    MatrixFValue: number,
    CellLeftPosition: number,
    CellTopPosition: number,
    Cell : object
}

export interface FrequencyOptionButton {
    value: string;
    variable: { value: boolean }; 
    id: string;
    name: string;
}

export interface MatrixSizeButton {
    size: string;
    name: string;
    isActive: boolean;
}